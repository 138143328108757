/* Includes all gift specific styling */

.main {
  @apply text-lg;

  & :global(> div[data-block="core/paragraph"]:last-of-type) {
    @apply pb-10 md:pb-15 lg:pb-20;
  }

  &:global(.no-hero) {
    @apply pt-14 sm:pt-20 lg:pt-30;

    &:global(.has-theme) {
      /* used to override campaign main padding */
      @apply pt-14 sm:pt-20 lg:pt-30;
    }

    &:global(.has-admin-bar) {
      @apply pt-26 sm:pt-32 lg:pt-40;
    }
  }

  &:global(.has-admin-bar) {
    @apply pt-12 sm:pt-12 md:pt-9;
  }
}
